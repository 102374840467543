<template>
  <section id="dashboard-ecommerce">
    <div class="sec1 d-flex justify-content-between pb-1">
      <h1 class="text-black">Offers{{ search }}</h1>
      <div>
        <!-- <button class="btn notofication-btn mr-1">
          <img src="../../../assets/images/client/filter.svg" alt="Snow" />  
          </button> -->

        <button id="popover-button-bottom" href="#" tabindex="0" type="button" class="btn notofication-btn mr-1">
          <div>
            <img src="../../../assets/images/client/filter.svg" alt="Snow" />
          </div>
        </button>

        <b-popover ref="popover" target="popover-button-bottom" triggers="hover" placement="Left"
          class="custom-popover pop-btn">
          <div class="center custom-vs-select" style="border: none;width:100%">
            <v-select class=""  :searchable="false" shadow placeholder="Select" v-model="statusFilter" @input="getClientStore()" :clearable="false" :options="['All','Active','Inactive']"/>
          </div>
        </b-popover>

        <button type="button" v-b-tooltip.hover.v-primary title="Export CSV" @click="exportToCSV" variant="outline-primary"
          class="btn notofication-btn mr-1">
          <img src="../../../assets/images/client/export.svg" alt="Snow" />
        </button>

        <button
          id="popover-button-variant"
          :style="popoverShow ? 'background-color: black;' : ''"
          href="#"
          tabindex="0"
          type="button"
          class="btn notofication-btn mr-3"
          @click="onPopovcerOpen()"
          v-b-tooltip.hover.v-primary title="Select All"
        >
          <div>
            <img
              v-if="!popoverShow"
              id="task1"
              src="../../../assets/images/client/task-square.svg"
              alt="Snow"
            />

            <img
              v-else
              id="task2"
              src="../../../assets/images/client/task-light.svg"
              alt="Snow"
            />
          </div>
        </button>

        <div v-if="popoverShow" class="popover-manual">
          <div class="popover-inner">
          <button @click="onPopovcerOpen" class="notofication-btn" style="color: #408dff; margin: 5px" size="sm">
            Cancel
          </button>

          <button  @click="HandelClickMultipleDublicate()" v-b-tooltip.hover.v-primary title="Copy" type="button" class="btn notofication-btn" style="background: #f4f9fd; margin: 8px">
            <img src="../../../assets/images/client/copy.png" alt="Snow" />
          </button>
          <button  @click="HandelClickMultipleEnableDisable()" type="button" class="btn notofication-btn ml-1" v-b-tooltip.hover.v-primary title="Active-Inactive" style="background: #f4f9fd; margin: 8px">
            <img src="../../../assets/images/client/circle.svg" alt="Snow" />
          </button>
          <button type="button" class="btn notofication-btn"  @click="HandelClickMultipleDelete()" v-b-tooltip.hover.v-danger title="Delete"
            style="background-color: rgba(246, 81, 96, 0.135735); margin: 8px">
            <img src="../../../assets/images/client/delete.png" alt="Snow" />
          </button>
        </div>
        <div class="popup-edge">
          </div>
        </div>
        <button type="button" class="btn btn-primary" @click="showAddModal" style="border-radius: 16px; padding: 16px" v-b-modal.modal>
          +&nbsp; Add Offer
        </button>

        <b-modal id="modal"  size="lg" ref="modal" class="m-2 custom--model" >
          <template #modal-header>
          <div style="width:50%;padding: 10px 20px;" class="form-row d-flex align-items-center">
            <div class="form-group">
              <h2 class="m-0">{{ edit?'Edit ':'Add ' }}Offer</h2>
            </div>
            <div class="form-group ml-2">
                <b-form-checkbox v-model="status" :checked="status" class="my-checkbox ml-1" name="check-button" switch>
                  <span class="switch-icon-left">
                    Active
                  </span>
                  <span class="switch-icon-right">
                    Inactive
                  </span>
                </b-form-checkbox>
              </div>
          </div>
            <b-button @click="closeModal" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
          <validation-observer ref="submitPrevent" #default="{ invalid }">
            <b-form @submit.prevent="submitForm">
            <b-form-group class="mb-2" label="Client">
              <validation-provider #default="{ errors }" name="Client" rules="required">
                <v-select v-model="client" :options="clientOption" label="name_en" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          

          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4">Offer Type</label>
              <VRadioGroup v-model="inlineRadio" inline>
                <div class="row m-0">
                  <div md="2" class="custom-radio mr-1 col-md-4 " :style="{
                    border:
                      inlineRadio == 'Coupon Code' ? '2px solid #3F8CFF' : '',
                  }">
                    <VRadio label="Coupon Code" value="Coupon Code" />
                  </div>
                  <div md="2" class="custom-radio col-md-4" :style="{
                    border: inlineRadio == 'Promotion' ? '2px solid #3F8CFF' : '',
                    }">
                    <VRadio label="Link" value="Promotion" />
                  </div>
                </div>
              </VRadioGroup>
            </div>
            <div v-if="$store.state.application.id == 2" class="form-group col-md-12">
              <label for="inputEmail4">Advetiser</label>
              <VRadioGroup v-model="advetiserRadio" inline>
                <div class="row m-0">
                  <div md="2" class="custom-radio d-flex col-md-4 mr-1" :style="{
                    border:
                      advetiserRadio == 'Itcan' ? '2px solid #3F8CFF' : '',
                  }">
                    <VRadio label="Itcan" value="Itcan" />
                  </div>
                  <div md="2" class="custom-radio col-md-4" :style="{
                    border:
                      advetiserRadio == 'Affiliate' ? '2px solid #3F8CFF' : '',
                  }">
                    <VRadio label="Affiliate" value="Affiliate" />
                  </div>
                </div>
              </VRadioGroup>
            </div>
            <div class="col-md-12">
              <b-form-group class="mb-2" label="Business Category">
                <validation-provider #default="{ errors }" name="Business Category" rules="required">
                  <v-select v-model="businessCategory" class="" :close-on-select="false" multiple label="name" :options="categoryOption"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group class="mb-2" label="Coupon Title (English)">
                <validation-provider #default="{ errors }" name="Coupon Title (English)" rules="required">
                  <b-form-input v-model="coupon_en" :state="errors.length > 0 ? false : null"
                    placeholder="Enter Coupon Title (English)" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              </div>
            <div class="col-md-6">
              <b-form-group class="mb-2" label="Coupon Title (Arabic)">
                <validation-provider #default="{ errors }" name="Coupon Title (Arabic)" rules="required">
                  <b-form-input v-model="coupon_ar" :state="errors.length > 0 ? false : null"
                    placeholder="Enter Coupon Title (Arabic)" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              </div>
            <div v-if="inlineRadio == 'Promotion'" class="col-md-6">
              <b-form-group class="mb-2" label="Promotion Link">
                <validation-provider #default="{ errors }" name="Promotion Link" rules="required">
                  <b-form-input v-model="promotion_link" :state="errors.length > 0 ? false : null"
                    placeholder="Enter Promotion Link" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div v-if="inlineRadio == 'Coupon Code'" class="col-md-6">
              <b-form-group class="mb-2" label="Coupon Code">
                <validation-provider #default="{ errors }" name="Coupon Code" rules="required">
                  <b-form-input v-model="coupon_code" :state="errors.length > 0 ? false : null"
                    placeholder="Enter Coupon Code" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group class="mb-2" label="Dummy Code">
                <!-- <validation-provider #default="{ errors }" name="Dummy Code" rules="required"> -->
                  <b-form-input v-model="dummy_code" 
                    placeholder="Enter Dummy Code" />
                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>
            </div>
            
            <div class="col-md-6">
              <b-form-group class="mb-2" label="Discount Percentage">
                <validation-provider #default="{ errors }" name="Discount Percentage" rules="required|integer">
                  <b-form-input v-model="discount_percentage" :state="errors.length > 0 ? false : null"
                    placeholder="Enter Discount Percentage" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group class="mb-2" label="Redirect Link">
                <!-- <validation-provider #default="{ errors }" name="Redirect Link" rules="required"> -->
                  <b-form-input v-model="redirect_link"
                    placeholder="Enter Redirect Link" />
                  <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group class="mb-2" label="Sequence">
                <validation-provider #default="{ errors }" name="Sequence" rules="required">
                  <b-form-input v-model="sequence"  :state="errors.length > 0 ? false : null"
                    placeholder="Enter Sequence" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            
            <div class="col-md-12 d-flex  justify-content-around" >
              <div class="d-flex align-items-center justify-content-center">
                <label class="col-form-label">Code Monetized</label>
                <b-form-checkbox
                  v-model="codeMonetized"
                  :checked="codeMonetized"
                  class="custom-control-primary ml-1"
                  name="check-button"
                />
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <label class="col-form-label">Link Monetized</label>
              <b-form-checkbox
                  v-model="linkMonetized"
                  :checked="linkMonetized"
                  class="custom-control-primary ml-1"
                  name="check-button"
                />
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <label class="col-form-label">Paid Advertiser</label>
              <b-form-checkbox
                  v-model="paid_advertiser"
                  :checked="paid_advertiser"
                  class="custom-control-primary ml-1"
                  name="check-button"
                />
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-md-12">
              <b-form-group class="mb-2" label="Description (English)">
                  <quill-editor v-model="description_en"/>
              </b-form-group>
              </div>
            <div class="col-md-12">
              <b-form-group class="mb-2" label="Description (Arabic)">
                  <quill-editor v-model="description_ar"/>
              </b-form-group>
              </div>
              <div class="col-md-6">
                  <b-form-group class="mb-2" label="Coupon Image URL">
                    <validation-provider #default="{ errors }" name="Coupon Image" rules="required">
                      <b-form-input v-model="image_url"  :state="errors.length > 0 ? false : null"
                        placeholder="Enter Coupon Image URL" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group class="mb-2" label="Image Alt Text">
                    <validation-provider #default="{ errors }" name="Image Alt Text" rules="required">
                      <b-form-input v-model="img_alt_text"  :state="errors.length > 0 ? false : null"
                        placeholder="Enter Image Alt Text" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
          </div>
          <div class="modal-footer1">
            <button type="button"  @click="AddOffer('AddAnother')" class="btn btn-default mr-1" style="border: 1px solid black" data-dismiss="modal">
              Add Another Offer
            </button>
            <button type="button"  @click="AddOffer"  class="btn btn-primary" >{{edit ? 'Update' :'Add'}}</button>
          </div>
            </b-form>
          </validation-observer>
          <!-- <div>
        <div style="display:flex;">
          <button type="button" class="btn btn-secondary">Cancel</button>
          <button type="button" class="btn btn-primary">OK</button>
        </div>
      </div> -->
        </b-modal>
         <b-modal id="showmodal" ref="showmodal" size="lg" class="m-2 custom--model">
          
          <template #modal-header>
            
            <div class="mr-2 d-flex h-100 align-items-center">
                  <b-img v-if="checkURL(getImage(showItem,'test'))" v-bind="mainProps" rounded="circle" :src="getImage(showItem,'test')"
                    alt="Circle image" class="d-inline-block" />
                  <b-img v-else v-bind="mainProps" rounded="circle"
                    :src="require('@/assets/images/no-pic.png')" alt="Circle image"
                    class="d-inline-block" />
                    <div class="ml-1">
                      <div class="font-weight-bolder h4 text-black mb-half">
                        {{ showItem.title_en }} - {{  showItem.title_ar }}
                      </div>
                      <div class="h5 text-muted mb-half">
                        {{showItem.redirect_url}}
                      </div>
                    <div class="font-weight-bolder text-black">
                        <span v-if="showItem.is_active" class="btn btn-outline-success-view btn-sm">Active</span>
                        <span v-else class="btn btn-outline-danger-view btn-sm">Inactive</span>
                    </div>
                  </div>
                </div>
            <b-button @click="$refs.showmodal.hide()" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
          <b-row class="p-1">
             <b-col md="12">
              <div>
                  <div class="mb-half">Coupon Code</div>
                  <div class="font-weight-bolder text-black">
                    <span class="font-weight-bolder h1" style="color: #3f8cff">{{ showItem.code }}</span>
                  </div>
                </div>
             </b-col>
            <b-col md="4" class="d-flex ">
              <div>
                <div class="mb-half">Sequence</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.sequence }}</div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex ">
              <div>
                <div class="mb-half">Offer Type</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.type }}</div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex ">
              <div>
                <div class="mb-half">Copied </div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.is_for ? 'Affiliate' : 'Itcan'  }} times</div>
              </div>
            </b-col>
            <b-col v-if="showItem.categories" md="4" class="d-flex ">
              <div>
                <div class="mb-half">Business Category</div>
                <div v-for="item in showItem.categories " :key="item.id" class="font-weight-bolder h4 text-black">{{item.name}}</div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex ">
              <div>
                <div class="mb-half">Fav Sequence</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.priority }}</div>
              </div>
            </b-col>            
            <b-col md="4" class="d-flex ">
              <div>
                <div class="mb-half">Geo Location</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.geo_location }}</div>
              </div>
            </b-col>
            <b-col md="4" class="d-flex ">
              <div>
                <div class="mb-half">ID</div>
                <div class="font-weight-bolder h4 text-black">{{ showItem.id }}</div>
              </div>
            </b-col>
            </b-row>
          </b-modal>
      </div>
    </div>
    
    <div v-if="isLoading" style="height: 100vh;" class="d-flex justify-content-center align-items-center">
      <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
    </div>
    <div class="d-flex justify-content-center align-items-center" v-else-if="offersData.length == 0">
      <img style="width:13cm; height:13cm" :src="host+'/NoDataFound.svg'" />
    </div>
    <div v-else class="drop-zone">
      <draggable v-model="offersData"  @change="draggableChange($event)" handle=".draggable-task-handle" tag="ul"
        class="todo-task-list media-list draggable-task-handle d-inline">
        <b-card no-body class="card-revenue-budget dash-total-card mb-2 drag-el" v-for="(item, index) in offersData" @dblclick="handelShow(item)"
          :key="index">
          <b-row class="p-1">
            <b-col md="4">
              <div class="d-flex align-items-center">
                <div>
                  <b-form-checkbox
                    v-if="popoverShow"
                    v-model="checkBoxIds[item.id]"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    inline
                    @change="CheckBoxHandelClick(item.id)"
                  />
                </div>
                <div class="ml-1 mr-2">
                  <b-img v-if="checkURL(getImage(item,'test'))" v-bind="mainProps" rounded="circle" :src="getImage(item,'test')"
                    alt="Circle image" class="d-inline-block" />
                  <b-img v-else v-bind="mainProps" rounded="circle"
                    :src="require('@/assets/images/no-pic.png')" alt="Circle image"
                    class="d-inline-block" />
                </div>
                <div>
                  <div class="font-weight-bolder text-black mb-half">
                    {{ item.app_name }}
                  </div>
                  <a :href="item.redirect_url" target="_blank">{{ item.redirect_url ? item.redirect_url.slice(0, 40) : '' }}{{ item.redirect_url ? item.redirect_url.length
                    > 40 ? '...' : '' : '' }}</a>
                </div>
              </div>
            </b-col>
            <b-col md="2" class="d-flex">
              <div>
                <div class="mb-half">Coupon Code</div>
                <div class="font-weight-bolder text-black">
                  <span class="font-weight-bolder" style="color: #3f8cff">{{ item.code }}</span>
                </div>
              </div>
            </b-col>
            <b-col md="2" class="d-flex">
              <div>
                <div class="mb-half">Sequence</div>
                <div class="font-weight-bolder text-black">{{item.sequence}}</div>
              </div>
            </b-col>
            <b-col md="2">
                <div>
                    <div class="mb-half">Status</div>
                    <div class="font-weight-bolder text-black">
                        <span v-if="item.is_active" class="btn btn-outline-success btn-sm">Active</span>
                        <span v-else class="btn btn-outline-danger btn-sm">Inactive</span>
                    </div>
                </div>
            </b-col>
            <b-col md="2" class="d-flex justify-content-center align-items-center text-black">
              <div
              :id="'popover-button-variant'+item.id"
              href="#"
                style="background: #f4f9fd; padding: 8px; border-radius: 16px"
              >
                <feather-icon icon="MoreVerticalIcon" size="25" />
              </div>
            </b-col>
          </b-row>
        <b-popover
          :target="'popover-button-variant'+item.id"
          triggers="hover"
          class="custom-popover"
        >
          <!-- <button
            @click="onPopovcerOpen3(item.id)"
            class="notofication-btn"
            style="color: #408dff; margin: 5px"
            size="sm"
          >
            Cancel
          </button> -->
          <button
            type="button"
            class="btn notofication-btn ml-1"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            @click="handelShow(item)"
            style="background: rgb(125 133 146 / 13%); margin: 8px"
            v-b-tooltip.hover.v-primary title="View"
          >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
          </svg>
          </button>
          <button
            type="button"
            class="btn notofication-btn ml-1"
            @click="handelEdit(item.id)"
            v-b-modal.modal
            style="background: rgb(63 140 254 / 13%); margin: 8px"
            v-b-tooltip.hover.v-primary title="Edit"
          >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3f8cfe" class="bi bi-pencil-square" viewBox="0 0 16 16">
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
          </svg>
          </button>
          <button
            type="button"
            class="btn notofication-btn"
            @click="handelDelete(item.id)"
            style="background-color: rgba(246, 81, 96, 0.135735); margin: 8px"
            v-b-tooltip.hover.v-danger title="Delete"
          >
            <img src="../../../assets/images/client/delete.png" alt="Snow" />
          </button>
        </b-popover>
        </b-card>
      </draggable>
      <div class="no-results" :class="{ show: !offersData.length }"></div>
    </div>

    <button v-if="!isLoading && offersData.length" type="button" class="w-auto btn slide-btn">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ paginationStart + 1 }} - {{ paginationEnd > paginationLength ?
          paginationLength : paginationEnd }} of {{ paginationLength }}</p>
        <feather-icon class="mx-1" icon="ArrowLeftIcon" @click="paginationHandelClick('back')" size="18" />
        <input v-model="pageNo" class="form-control"
          style="width:70px" @input="pagechange" type="number" />
        <feather-icon class="mx-1" icon="ArrowRightIcon" @click="paginationHandelClick('next')" size="18" />
      </div>
    </button>

    <button v-if="popoverShow && !isLoading" id="seeall-btn" type="button" class="w-auto btn see-btn" @click="selectAllClick()">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ selectAllLabel }}</p>
      </div>
    </button>
  </section>
</template>

<script>
import draggable from "vuedraggable";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer } from "@validations";
import { BImg } from 'bootstrap-vue'
import json2csv from 'json2csv';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import vSelect from 'vue-select';
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { mapState } from 'vuex';
export default {
  components: {
    draggable,
    vSelect,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    quillEditor, BImg
  },
  data() {
    return {
      inlineRadio: "Coupon Code",
      advetiserRadio: "Itcan",
      value: "1",
      required, integer,
      mainProps: {
        width: 70,
        height: 70,
        class: 'm1',
      },
      data: {},
      businessCategory:[],
      filter: 'All',
      selectAllLabel: 'Select All',
      baseURL: process.env.VUE_APP_BASE_URL,
      host:window.location.origin,
      clientOption: [],
      offersData: [],
      offersData1: [],
      popoverShow: false,
      edit: false,
      pageNo: 1,
      paginationStart: 0,
      sequence1:0,
      isLoading: true,
      paginationEnd: 10,
      paginationLength: 0,
      statusFilter:"All",
      status:'',
      client:'',
      businessCategory:'',
      coupon_en:'',
      coupon_ar:'',
      promotion_link:'',
      coupon_code:'',
      dummy_code:'',
      redirect_link:'',
      description_en:'',
      description_ar:'',
      discount_percentage:'',
      codeMonetized:false,
      linkMonetized:false,
      paid_advertiser:false,
      sequence: '',
      checkBoxIds: {},
      categoryOption:[],
      selectAll: false,
      showItem:{},
      image_url:'',
      img_alt_text:''
    };
  },
  created() {
  },
  mounted() {
    // this.getClientStore();
    this.getAllClient();
  },
  watch: {
   'sidebar': {
     handler: 'checkIsValid',
     immediate: true, 
   },
  '$store.state.application': {
    handler: 'checkIsValid',
    immediate: true,
  },
  },
  computed: {
      ...mapState(['sidebar']),
    search() {
      // let data = []
      let search = this.$store.state.searchData
      if(this.$store.state.application){
        this.getSearch(search);
      }
    },
  },
  methods: {
    checkIsValid() {
      if (this.sidebar) {
        let is_valid = this.sidebar.some(data => data.name == 'Offers');
        if (!is_valid) {
          this.$router.push('/');
        }
      }
    },
   async AddAnotherOffer(){
      this.AddOffer('AddAnother')
    },
    reset(){
      this.edit = false
      this.editItem = ''
        this.client='';
        this.advetiserRadio='Itcan';
        this.inlineRadio='Coupon Code';
        this.discount_percentage='';
        this.businessCategory=[]
        this.coupon_en='';
        this.coupon_ar='';
        this.promotion_link='';
        this.coupon_code='';
        this.codeMonetized=false;
        this.linkMonetized=false;
        this.paid_advertiser=false;
        this.dummy_code='';
        this.redirect_link='';
        this.description_en='';
        this.description_ar='';
        this.status= false;
        this.sequence1 = 0;
        this.sequence = 0;
        this.image_url='',
        this.img_alt_text=''
    },
    showAddModal(){
      this.popoverShow=false
      this.edit = false
      this.editItem = ''
        this.client='',
        this.advetiserRadio='Itcan',
        this.inlineRadio='Coupon Code',
        this.discount_percentage='',
        this.businessCategory=[]
        this.coupon_en='',
        this.coupon_ar='',
        this.promotion_link='',
        this.coupon_code='',
        this.codeMonetized=false,
        this.linkMonetized=false,
        this.paid_advertiser=false,
        this.dummy_code='',
        this.redirect_link='',
        this.description_en='',
        this.description_ar='',
        this.status= false,
        this.sequence1 = 0,
        this.sequence = 0,
        this.image_url=''
        this.img_alt_text=''
       this.$http.post(`${this.baseURL}/offers/get-sequence`,{app_id: this.$store.state.application.id}).then((json) => {
        this.sequence = json.data.sequence
        this.sequence1 = json.data.sequence
      })
    },
    handelEdit(id) {
      this.reset()
      this.edit = true
      this.popoverShow = false
      this.$http.post(`${this.baseURL}/offers/view/${id}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((json) => {
          this.editItem = json.data.data
          this.client=this.editItem.client
          this.advetiserRadio=this.editItem.is_for ? 'Affiliate' : 'Itcan'
          this.inlineRadio=this.editItem.type
          this.discount_percentage=this.editItem.discount_percentage
          this.businessCategory=this.editItem.business_category
          this.coupon_en=this.editItem.title_en
          this.coupon_ar=this.editItem.title_ar
          this.promotion_link=this.editItem.promotion_link
          this.coupon_code=this.editItem.code
          this.dummy_code=this.editItem.dummy_code
          this.redirect_link=this.editItem.redirect_url
          this.description_en=this.editItem.desc_en
          this.description_ar=this.editItem.desc_ar
          this.codeMonetized = this.editItem.code_monetized ? true : false
          this.linkMonetized = this.editItem.link_monetized ? true : false
          this.paid_advertiser = this.editItem.paid_advertiser ? true : false
          this.image_url = this.editItem.image_url
          this.img_alt_text = this.editItem.img_alt_text
          this.status = this.is_active ? true : false
          this.sequence = parseInt(this.editItem.sequence)
          this.sequence1 = 0
              this.$http.post(`${this.baseURL}/offers/get-sequence`,{app_id: this.$store.state.application.id}).then((json) => {
                this.sequence1 = json.data.sequence - 1
              })
            this.status = this.editItem.is_active ? true : false
        })
        this.$forceUpdate();
    },
    draggableChange(e) {
      let search = this.$store.state.searchData
      let data = {
        newIndex: this.offersData1[e.moved.newIndex].sequence,
        oldIndex: this.offersData1[e.moved.oldIndex].sequence,
        pageno: this.pageNo,
        app_id:this.$store.state.application.id,
        statusFilter: this.statusFilter
      }
      if (search) { data = { ...data, search: search } }
      this.$http.post(`${this.baseURL}/offers/sequence-change`, data,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          this.offersData = []
          this.offersData1 = []
          this.offersData = res.data.data
          this.paginationLength = res.data.totaldata
          this.offersData1 = res.data.data
        })
    },
    onCopy() {
      this.$refs.showmodal.hide()
      this.$http.post(`${this.baseURL}/offers/copy-coupon`, {id:this.showItem.id},
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Code copied',
                icon: 'CheckIcon',
              },
            })
        })
    },
    onError() {
      this.$toast({ 
        component: ToastificationContent,
        props: {
          title: 'Failed to copy Code!',
          icon: 'XIcon',
        },
      })
    },
    handelShow(item){
      this.$refs.showmodal.show()
      this.popoverShow=false
      this.showItem = item
    },
    checkURL(url) {
      if(url){
        const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
        return pattern.test(url ? url.replace(/\s/g,'%20') : url);
      }
      return false
    },
    getSearch: _.debounce(function (search) {
      this.pageNo = 1
      this.paginationStart = 0
      this.paginationEnd = 10
      // if(search){
      this.isLoading = true
      this.$http.post(`${this.baseURL}/offers/search`, {statusFilter:this.statusFilter, search: search, pageno: this.pageNo, app_id: this.$store.state.application.id },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          this.offersData = res.data.data
          this.paginationLength = res.data.totaldata
          this.offersData1 = res.data.data
          this.isLoading = false
        })
      // }
    }, 1000),
    
    closeModal() {
      this.$refs['modal'].hide()
    },
    solution2(message) {
       try {
        this.$copyText(message, this.$refs.modalBody);
        this.onCopy();
      } catch (error) {
        this.errorMessage = 'Error copying text: ' + error.message;
        this.onError();
      }
    },
    exportToCSV() {
      // Convert the data to CSV format using json2csv library
      // let array = []
      // this.offersData.map(item => {
      //   let data = {
      //   name_en:item.name_en,
      //   name_ar:item.name_ar,
      //   category: item.categories.map((item)=>{ return item.name}).join(", "),
      //   description_en:item.description_en,
      //   description_ar:item.description_ar,
      //   is_active:item.is_active,
      //   sequence:item.sequence,
      //   website:item.website,
      //   image:item.image,
      //   search_keyword_en:item.search_keyword_en,
      //   search_keyword_ar:item.search_keyword_ar,
      // }
      // array.push(data);
      // })
      const csvData = json2csv.parse(this.offersData);
      const link = document.createElement('a');
      link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
      link.download = 'Offers.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    closeModal() {
      this.$refs['modal'].hide()
    },
    AddOffer(value) {
      let data = {
        app_id:this.client.id,
        is_for:this.advetiserRadio == 'Affiliate'? 1 : 0,
        coupon_user_id:this.$store.state.application.id,
        type:this.inlineRadio,
        discount_percentage:this.discount_percentage,
        app_name:this.client.name_en,
        category_id:this.businessCategory.map(option => option.id),
        title_en:this.coupon_en,
        title_ar:this.coupon_ar,
        promotion_link:this.promotion_link,
        code:this.coupon_code,
        dummy_code:this.dummy_code,
        redirect_url:this.redirect_link,
        desc_en:this.description_en,
        desc_ar:this.description_ar,
        is_active: this.status ? 1 : 0,
        sequence: this.sequence,
        code_monetized:this.codeMonetized ? 1 : 0,
        link_monetized:this.linkMonetized ? 1 : 0,
        paid_advertiser:this.paid_advertiser ? 1 : 0,
        image_url:this.image_url,
        img_alt_text:this.img_alt_text,
      }
      if (this.edit) {
        data = { id: this.editItem.id, ...data }
      }
      if(this.sequence > this.sequence1){
        this.$swal({
                title: "Warning!!",
                text: `Sequence must be Lower than ${this.sequence1}`,
                icon: "warning",
                customClass: {
                  confirmButton: "btn btn-warning",
                },
                buttonsStyling: false,
              });
      }else{
      this.$refs.submitPrevent.validate().then((success) => {
         if(!success){
        this.$swal({
                title: "Warning!!",
                text: `Please Select Require Fields`, 
                icon: "warning",
                customClass: {
                  confirmButton: "btn btn-warning",
                },
                buttonsStyling: false,
              });
        }else{

        this.$http.post(`${this.baseURL}/offers/${this.edit ? 'update' : 'create'}`, data,
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((json) => {
            if(value !== 'AddAnother' ){
            if (json.data.status == 'error') {
              let text = Object.keys(json.data.data).length > 0 ? Object.keys(json.data.data).map(item => {return `${item}:${json.data.data[item]}`}) : json.data.message
                  ? `${json.data.message}` 
                    : "Error"
              this.$swal({
                title: "Error!!",
                text: text,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            } else {
              this.getClientStore()
              this.checkBoxIds = {}
              this.$refs['modal'].hide()
              this.$swal({
                title: "Submitted",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                    ? `${json.data.success}`
                    : "Submitted SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              
              // if(value == 'addanother'){
              // this.$refs['modal'].show()
              // this.showAddModal()
              // }
            }
            }else{
              this.$swal({
                title: "Submitted",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                    ? `${json.data.success}`
                    : "Submitted SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });

              this.$refs['modal'].show()
              this.$refs.submitPrevent.reset()
              this.showAddModal()
              this.getClientStore()
            }
            this.$store.commit('clearState')
          })
        }
      })
    }
    },
    getImage(item,value){
      if(item.allImages !== undefined){
    if(this.$store.state.application.id == 1){
    let data = item.allImages.filter(item1 => (item1.asset == 'Web Slider' || item1.asset == 'Web Coupon Banner'))
    if(data.length > 0){
      if(value == 'all'){  
     return data[0]
      }else{
        return data[0].image.replace(/\s/g,'%20')
      }
    }else{
      return undefined
    }
    }
    else if(this.$store.state.application.id == 2){
    let data = item.allImages.filter(item1 => (item1.asset == 'Web Slider' || item1.asset == 'Web Coupon Banner'))
    if(data.length > 0){
      if(value == 'all'){  
     return data[0]
      }else{
        return data[0].image.replace(/\s/g,'%20')
      }
    }else{
      return undefined
    }
    }
    else if(this.$store.state.application.id == 3){
    let data = item.allImages.filter(item1 => (item1.asset == 'Coupon Rush Client' || item1.asset == 'Coupon Rush Coupon') ||  item1.asset == 'Coupon Rush Banner')
    if(data.length > 0){
      if(value == 'all'){  
     return data[0]
      }else{
        return data[0].image.replace(/\s/g,'%20')
      }
    }else{
      return undefined
    }
    }
    else if(this.$store.state.application.id == 4){
     let data = item.allImages.filter(item1 => item1.asset == 'Mobile Category')
    if(data.length > 0){
      if(value == 'all'){  
     return data[0]
      }else{
        return data[0].image.replace(/\s/g,'%20')
      }
    }else{
      return undefined
    }
    }
    // else if(this.$store.state.application.id == 5){
    //   let data = item.allImages.filter(item1 => (item1.asset == 'Old SC Web Coupon Banner'))
    // if(data.length > 0){
    //   if(value == 'all'){  
    //  return data[0]
    //   }else{
    //     return data[0].image.replace(/\s/g,'%20')
    //   }
    // }else{
    //   return undefined
    // }
    // }
    else if(this.$store.state.application.id == 6){
    let data = item.allImages.filter(item1 => (item1.asset == 'Coupon Rush Client' || item1.asset == 'Coupon Rush Coupon') ||  item1.asset == 'Coupon Rush Banner')
    if(data.length > 0){
      if(value == 'all'){  
     return data[0]
      }else{
        return data[0].image.replace(/\s/g,'%20')
      }
    }else{
      return undefined
    }
    }
    else if(this.$store.state.application.id == 7){
    let data = item.allImages.filter(item1 => (item1.asset == 'Lala Web Slider' || item1.asset == 'Lala Web Coupon Banner'))
    if(data.length > 0){
      if(value == 'all'){  
     return data[0]
      }else{
        return data[0].image.replace(/\s/g,'%20')
      }
    }else{
      return undefined
    }
    }
    else if(this.$store.state.application.id == 8){
      let data = item.allImages.filter(item1 => (item1.asset == 'Key Rush Coupon Detail' || item1.asset == 'Key Rush Coupon') ||  item1.asset == 'Key Rush Client')
    if(data.length > 0){
      if(value == 'all'){  
     return data[0]
      }else{
        return data[0].image.replace(/\s/g,'%20')
      }
    }else{
      return undefined
    }
    }else{
      return undefined
    }
  }else{
    return undefined
  }
    this.$forceUpdate();
    },
    handelChangeFilter() {
      if (this.filter == "All") {
        this.offersData = this.offersData1
      }
      else if (this.filter == "Active") {
        let data = []
        data = this.offersData1.filter(item => item.is_active == 1)
        this.offersData = data
      }
      else if (this.filter == "Inactive") {
        let data = []
        data = this.offersData1.filter(item => item.is_active == 0)
        this.offersData = data
      }
    },
    pagechange(){
      if(this.pageNo>(this.paginationLength/10)){
        this.pageNo = parseInt(this.paginationLength/10)
      }
      if(this.pageNo == 0){
        this.pageNo = 1
      }
        this.paginationStart = (this.pageNo*10)-10
        this.paginationEnd = (this.pageNo*10)
      let search = this.$store.state.searchData
          if (search) {
            this.searchPaginationHandelClick(search)
          } else {
            this.normalPaginationHandelClick(search)
          }
    },
    searchPaginationHandelClick: _.debounce(function (search) {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/offers/search`, {statusFilter:this.statusFilter, search: search, pageno: this.pageNo, app_id: this.$store.state.application.id }).then((res) => {
        this.offersData = res.data.data
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        // this.pageNo=res.data.page
        this.paginationLength = res.data.totaldata
        this.offersData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    normalPaginationHandelClick: _.debounce(function () {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/offers/index`, {statusFilter:this.statusFilter, pageno: this.pageNo, app_id: this.$store.state.application.id }).then((res) => {
        this.offersData = res.data.data
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        // this.pageNo=res.data.page
        this.paginationLength = res.data.totaldata
        this.offersData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    paginationHandelClick(value) {
      let search = this.$store.state.searchData
      if (value == 'back') {
        if (this.paginationStart > 1) {
          this.pageNo--
          this.paginationStart = this.paginationStart - 10
          this.paginationEnd = this.paginationEnd - 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
      else if (value == 'next') {
        if (this.paginationEnd < this.paginationLength) {
          this.pageNo++
          this.paginationStart = this.paginationStart + 10
          this.paginationEnd = this.paginationEnd + 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
      },
      HandelClickMultipleEnableDisable() {
        let arr = []
        Object.keys(this.checkBoxIds).map((key) => {
          if (this.checkBoxIds[key] == true) {
            arr.push(key)
          }
        });
        if (arr.length > 0) {
          this.isLoading = true
          this.$http.post(`${this.baseURL}/offers/multi-enable-disable`, { ids: arr },
            {
              headers: {
                'Content-Type': 'application/json'
              },
              withCredentials: true
            }).then((res) => {
              this.$swal({
                title: "Updated",
                text: res.data.message
                  ? `${res.data.message}`
                  : res.data.success
                    ? `${res.data.success}`
                    : "Updated",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.getClientStore()
              this.popoverShow= false
              this.checkBoxIds = {} 
              this.selectAllLabel = 'Select All'
              this.$forceUpdate()
            })
        } else {
          this.$swal({
            title: "Warning",
            text: "Select atleast one item",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-warning",
            },
            buttonsStyling: false,
          });
        }
      },
      HandelClickMultipleDelete() {
        let arr = []
        Object.keys(this.checkBoxIds).map((key) => {
          if (this.checkBoxIds[key] == true) {
            arr.push(key)
          }
        });
        if (arr.length > 0) {
          this.isLoading = true

          this.$http.post(`${this.baseURL}/offers/multi-delete`, { ids: arr },
            {
              headers: {
                'Content-Type': 'application/json'
              },
              withCredentials: true
            }).then((res) => {
              this.$swal({
                title: "Deleted",
                text: res.data.message
                  ? `${res.data.message}`
                  : res.data.success
                    ? `${res.data.success}`
                    : "Deleted",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.getClientStore()
              this.popoverShow= false
            this.checkBoxIds = {} 
            this.selectAllLabel = 'Select All'
              this.$forceUpdate()
            })
        } else {
          this.$swal({
            title: "Warning",
            text: "Select atleast one item",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-warning",
            },
            buttonsStyling: false,
          });
        }
      },
      HandelClickMultipleDublicate() {
        let arr = []
        Object.keys(this.checkBoxIds).map((key) => {
          if (this.checkBoxIds[key] == true) {
            arr.push(key)
          }
        });
        if (arr.length > 0) {
          this.isLoading = true
          this.$http.post(`${this.baseURL}/offers/copy`, { ids: arr },
            {
              headers: {
                'Content-Type': 'application/json'
              },
              withCredentials: true
            }).then((res) => {
              this.$swal({
                title: "Success",
                text: res.data.message
                  ? `${res.data.message}`
                  : res.data.success
                    ? `${res.data.success}`
                    : "Success",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.getClientStore()
            this.popoverShow= false
            this.checkBoxIds = {} 
            this.selectAllLabel = 'Select All'
              this.$forceUpdate()
            })
        } else {
          this.$swal({
            title: "Warning",
            text: "Select atleast one item",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-warning",
            },
            buttonsStyling: false,
          });
        }
      },
    handelDelete(id) {
      this.$http.post(`${this.baseURL}/offers/delete`, { id: id },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          this.$swal({
            title: "Deleted",
            text: res.data.message
              ? `${res.data.message}`
              : res.data.success
                ? `${res.data.success}`
                : "Deleted",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.getClientStore()
          this.checkBoxIds = {}
          this.$forceUpdate()
        })
    },
    selectAllClick() {
      let data = Object.keys(this.checkBoxIds).filter(item => this.checkBoxIds[item])
      if (this.selectAllLabel == 'Deselect All') {
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
      } else {
        this.selectAllLabel = 'Deselect All'
        this.offersData.map(item => {
          this.checkBoxIds[item.id] = true
        })
      }
      this.$forceUpdate()
    },      
    CheckBoxHandelClick(id) {
      // this.checkBoxIds[id] = this.checkBoxIds[id] == undefined ? false : this.checkBoxIds[id]
      // this.checkBoxIds[id] = this.checkBoxIds[id] == true ? false : true
      let data = Object.keys(this.checkBoxIds).filter(item => this.checkBoxIds[item])
      if (data.length == 10) {
        this.selectAllLabel = 'Deselect All'
      } else {
        this.selectAllLabel = 'Select All'
      }
      this.$forceUpdate()
    },
    onPopovcerOpen() {
      this.popoverShow = !this.popoverShow;
    },
    async getClientStore() {
      if (this.$store.state.application) {
        this.isLoading = true
        this.pageNo = 1
      this.paginationEnd = 10,
      this.paginationStart = 0,
        this.$http.post(`${this.baseURL}/offers/index`, {statusFilter:this.statusFilter, pageno: this.pageNo, app_id: this.$store.state.application.id }).then((res) => {
          this.offersData = res.data.data
          this.paginationLength = res.data.totaldata
          this.offersData1 = res.data.data
          this.isLoading = false
          this.checkBoxIds = {}
        })
      }
    },
    getAllClient(){

      this.$http.post(`${this.baseURL}/client/get-all`).then((res) => {
          this.clientOption = res.data.data
          this.checkBoxIds = {}
        })
      this.$http.post(`${this.baseURL}/categories/get-all`).then((res) => {
          this.categoryOption = res.data.data
          this.checkBoxIds = {}
        })
    }
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
// .draggable-task-handle {
//     transform: translateY(-50%);
//     visibility:visible;
//     cursor: move;

//     .todo-task-list .todo-item:hover & {
//       visibility: hidden;
//     }
// }

.popup-edge{
position: absolute;
width:50px;
height: 50px;
background-color: #ffffff;
right:0;
top:13px;
transform: rotate(45deg);
}
.popover-manual{
  position: absolute;
    right: 6.5cm !important;
    background-color: white;
    border-radius: 26px;
    padding: 5px 15px;
    left: auto;
    top:100px !important;
    // z-index: 10000;
}
.popover-inner{
  display: flex;
  z-index: 1;
  position: relative;
}
.custom-radio {
  border: 1px solid #d8e0f0;
  border-radius: 10px;
  color: #000 !important;
}
.v-label{
  margin-top: 5px;
}
.status-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  border: 1px solid #0ac947;
  border-radius: 4px;
}

.mb-half {
  margin-bottom: 5px;
}

.notofication-btn {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 12px 12px;
}

.slide-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  padding: 15 px;
  margin: 0;
  width: 200px;
  float: right;
}

.see-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  width: 125px;
}

.bs-popover-left {
  border-radius: 14px !important;
  border: transparent;
  min-width: 140px !important;
  max-width: 350px !important;
}

.bs-popover-left .popover-body {
  display: flex;
  justify-content: space-between;
  border: none;
}

.bs-popover-bottom {
  background-color: #ffffff;
  // height: 190px;
  border: transparent;
  border: none;
  border-radius: 14px;
}

.popover-body {
  border: none !important;
}

.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #ffffff;
}

.vs-select__options__content {
  background-color: #ffffff !important;
}

.pop-btn {
  padding: 0 !important;
}

.modal-body {
  padding: 0px 40px 40px 40px !important
}

.form-control {
  border-radius: 14px;
}

.modal-content {
  border-radius: 24px;
}

.modal-header {
  border-radius: 24px;
  height:fit-content !important;
}

textarea.form-control {
  min-height: 150px !important;
}

.modal-footer {
  display: none;
}

.modal-footer1 {
  display: flex;
  float: right;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem 1.4rem;
}

.btn-outline-danger-view{
  width: 58px;
  padding: 0px 0px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight:500;
  font-size: 16px;
  line-height: 12px;
  text-align: center;
  color: #ea5455;
  // border: 1px solid #ea5455;
  border-radius: 4px;
}

.btn-outline-success-view{
  width: 58px;
  padding: 0px 0px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight:500;
  font-size: 18px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  // border: 1px solid #0ac947;
  border-radius: 4px;
}
</style>
